import React from "react"

import Header from '../components/header'
import Footer from '../components/footer'


export default class PrivacyPolicy extends React.Component {

render(){
return(
<>  
  <div className="wrapper default ">
    <Header />
      <div id="primary" class="boxed-layout-header page-header header-small" data-parallax="active">
        <div class="container">
          <div class="row">
            <div class="col-md-10 col-md-offset-1 text-center">
              <h1 class="hestia-title ">Privacy Policy</h1>
            </div>
          </div>
        </div>
        <div class="header-filter header-filter-gradient"></div>
      </div>

      <div class="main  main-raised ">
        <div class="blog-post ">
          <div class="container">
            <article id="post-3" class="section section-text">
              <div class="row">
                <div class="col-md-8 page-content-wrap  col-md-offset-2">
                  <div data-elementor-type="post" data-elementor-id="3" class="elementor elementor-3" data-elementor-settings="[]">
                    <div class="elementor-inner">
                      <div class="elementor-section-wrap">
                        <section data-id="4964c343" class="elementor-element elementor-element-4964c343 elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-top-section" data-element_type="section">
                          <div class="elementor-container elementor-column-gap-default">
                            <div class="elementor-row">
                              <div data-id="6d4a1bec" class="elementor-element elementor-element-6d4a1bec elementor-column elementor-col-100 elementor-top-column" data-element_type="column">
                                <div class="elementor-column-wrap  elementor-element-populated">
                                  <div class="elementor-widget-wrap">
                                    <div data-id="34837f21" class="elementor-element elementor-element-34837f21 elementor-widget elementor-widget-text-editor" data-element_type="text-editor.default">
                                      <div class="elementor-widget-container">
                                        <div class="elementor-text-editor elementor-clearfix">
                                          <div style={{direction: 'ltr', textAlign: 'left'}}>
                                            <p align="center"><b><u>Privacy Policy &#8211; Outicks™</u></b></p>
                                            <p align="center">Last updated: February 5th, 2019</p>
                                            <p>Outicks™ is Software developed and owned by Prometheus Inspiring Technology Ltd with registered address at: 33 HaBarzel, Tel-Aviv, Israel (the “<b>Company</b>”).</p>
                                            <p>Outicks™ is provided on a user subscription-based service (the “<b>Service</b>”).</p>
                                            <p>To enable the Company to provide you the Service, the Company stores and processes the Service user’s Personal Information for the purpose of the Service’s features and functionality.</p>
                                            <p>This Privacy Policy discloses to the Service users (the “<b>User</b>”) about the User’s personal information which is collected, processed and stored while using the Service.</p>
                                            <p>“<b>Personal Information</b>“<span dir="RTL"></span><span dir="RTL"></span><span lang="HE" dir="RTL"><span dir="RTL"></span><span dir="RTL"></span>– </span><span dir="LTR"></span><span dir="LTR"></span><span dir="LTR"></span><span dir="LTR"></span> any information which may identify a person, directly or indirectly, in reference or specific, mainly information which relates to a specific person such as identification or passport numbers, financial data, medical data, location information, <span dir="RTL"></span><span dir="RTL"></span><span dir="RTL"> </span>and other information which is considered as personal information.<span lang="HE" dir="RTL"></span></p>
                                            <p>The use of the Service is subject to the full acceptance of the Terms and Conditions (the “<b>Terms</b>”). This Privacy Policy is an integral part of the Terms and will apply by the Company while using the Service.</p>
                                            <p>The Company provides the Service, which is cloud based, by using third parties’ providers and sub-contractors (“<b>Third Parties</b>”). A list of the third Parties which are used by the Company to provide the Service, can be reviewed https://outicks.com/terms-of-service/. By accepting the Terms, User acknowledges that each one of the Third Parties privacy policies, have been reviewed by the Company and approved to comply with the Company’s Privacy Policy herein.</p>
                                            <p>To connect to the Service, User will need to login to the Service’s internet website or mobile application. Visitors may find information about the Service and start a free trial period.</p>
                                            <p>WHILE USING THE SERVICE, USER MAY UPLOAD AND STORE DOCUMENTS AND INFORMATION, WHICH MAY BE REGARDED AS PERSONAL INFORMATION<span dir="RTL"></span><span dir="RTL"></span><span dir="RTL"> </span>AND LOCAL PRIVACY LEGISLATION MAY APPLY ON SUCH PERSONAL INFORMATION WHICH IS STORED OR USED, WHILE USING THE SERVICE.</p>
                                            <p>PERSONAL INFORMATION CAN BE STORED AND DOCUMENTED ON VIDEO FILES, AUDIO FILES, PHOTOGRAPHS, LOCATION DATA, MEDICAL DATA, FINANCIAL DATA AND ANY OTHER INFORMATION WHICH USER MAY CHOOSE TO DOCUMENT (hereinafter “<b>User Generated Content</b>”)</p>
                                            <p>THE COMPANY PROVIDES THE SOFTWARE AS A SERVICE AND DOES NOT CONTROL, OR HAS THE ABILITY TO CONTROL, THE NATURE AND CONTENT OF THE USER GENERATED CONTENT.</p>
                                            <p>THIS PRIVACY POLICY REFERS TO THE INFORMATION WHICH IS COLLECTED BY THE COMPANY AND THE SERVICE AND WILL NOT APPLY ON USER GENERATED CONTENT.</p>
                                            <h1>Collected Personal information</h1>
                                            <ol>
                                              <li>
                                                <span dir="LTR"></span>From visitors on our website and on the mobile apps:
                                                <ol>
                                                  <li><span dir="LTR"></span>IP and browser information</li>
                                                  <li><span dir="LTR"></span>Cookies and Pixel-Tags as described hereinafter</li>
                                                  <li><span dir="LTR"></span>Client device information</li>
                                                </ol>
                                              </li>
                                            </ol>
                                            <ol start="2">
                                              <li><span dir="LTR"></span>From Registered Users:</li>
                                            </ol>
                                            <ol>
                                              <li><span dir="LTR"></span>All information collected from visitors in section 1.</li>
                                              <li><span dir="LTR"></span>Full name</li>
                                              <li><span dir="LTR"></span>Home/Office address</li>
                                              <li><span dir="LTR"></span>Email</li>
                                              <li><span dir="LTR"></span>Telephone</li>
                                              <li><span dir="LTR"></span>Login &amp; Passwords</li>
                                              <li><span dir="LTR"></span>Payment details</li>
                                              <li><span dir="LTR"></span>Occupation</li>
                                              <li><span dir="LTR"></span>Business name and address</li>
                                              <li><span dir="LTR"></span>Geo location of sites/projects</li>
                                              <li><b> </b><span dir="LTR"></span><b>User Generated Content</b></li>
                                              <li><span dir="LTR"></span>Any Personal Information which may be, or may become, relevant for the purposes which the Personal Information is required to provide the Service.</li>
                                            </ol>
                                            <p><span lang="HE" dir="RTL"> </span></p>
                                            <ol start="3">
                                              <li><span dir="LTR"></span>Why we collect User’s Personal Information:</li>
                                            </ol>
                                            <ol>
                                              <li><span dir="LTR"></span>The use of the Service requires Users to provide Personal Information which allow the Service to recognize registered User’s history and preferences and create a valid User account.</li>
                                              <li><span dir="LTR"></span>A valid updated User’s account provides User a personalized interactive use of the Service.</li>
                                              <li><span dir="LTR"></span>Provide User with full access to features of the Service and offer online support.</li>
                                              <li><span dir="LTR"></span>Enable User interact and group with other User’s in multiple User’s account.</li>
                                              <li><span dir="LTR"></span>Allow the Company suggests User sales opportunities, commercial offers, orders, invoices, forms, surveys, newsletters.</li>
                                            </ol>
                                            <p><b>The Service does not monitor or control <u>User Generated Content</u> and therefor, User’s responsibility to obtain legal consent from data subjects, who their Personal Information is being collected, stored, processed and used in the <u>User Generated Content</u>. </b></p>
                                            <h1>Personal Information Retention</h1>
                                            <p><b> </b></p>
                                            <ol start="4">
                                              <li><span dir="LTR"></span>The Company does not store Personal Information for more than required to provide User with the Service. Retention periods vary depending on the categories, the Personal Information is related to. <b></b></li>
                                            </ol>
                                            <p><b> </b></p>
                                            <h1>Data Processing Policy [<a href="/data-processing-policy">link</a>] <b></b></h1>
                                            <ol start="5">
                                              <li><span dir="LTR"></span><b>The Company complies with local privacy legislation and it will process Personal Information according to its Data Processing Policy. The company may amend Data Processing Policy from time to time without prior notice. </b></li>
                                            </ol>
                                            <h1>Recipients</h1>
                                            <ol start="6">
                                              <li><span dir="LTR"></span>The Company may share User’s Personal Information with business partners for promotion and marketing of products and services which may be offered to Users, based on User’s account profile.</li>
                                            </ol>
                                            <h1>Cookies and Pixel-Tags</h1>
                                            <ol start="7">
                                              <li><span dir="LTR"></span>Cookies are text files stored and used to record data. Such data may include Personal Information or non-Personal Information which provides data about the User’s browsing and the use of the Service. Cookies may remain at User’s device after logging off from the Service to be used on User’s repeating entrances to the Service each time user logs in.</li>
                                              <li><span dir="LTR"></span>The Company also uses Pixel-Tags to identify and track the User’s usage of the Company’s website and interaction with the Company’s distributed emails. Pixel-Tags (also referred as web-beacons) and are not stored on the User’s device,</li>
                                              <li><span dir="LTR"></span>To use the Service’s full features, the Service may use cookies, pixel-tags or similar technology that may collect and store Personal Information.</li>
                                            </ol>
                                            <h1>Data Security</h1>
                                            <ol start="10">
                                              <li><span dir="LTR"></span>The Company undertakes to ensure the security and protection of User’s Personal Information, that user consented the Company to store and process. The Company implemented data safety policy and have been certified as compliant with the ISO <span dir="RTL"></span><span dir="RTL"></span><span lang="HE" dir="RTL"><span dir="RTL"></span><span dir="RTL"></span>27001</span></li>
                                            </ol>
                                            <h1>Third parties’ services</h1>
                                            <ol start="11">
                                              <li><span dir="LTR"></span>For the purpose of the Service, the Company uses third parties’ services and platforms. An updated list of the Third Parties (“Sub-Contractors”) platforms and services is annexed to the <a href="/data-processing-policy"><u>Data Processing Policy document Annex</u></a> and<span dir="RTL"></span><span dir="RTL"></span><span dir="RTL"> </span>Client’s responsibility to verify that Sub-Contractors process Personal Information in a manner which does not breach any of the Client’s Data Subjects rights.</li>
                                              <li><span dir="LTR"></span>The Company may replace or cancel any Sub-Contractors service according to the Service’s requirements. The Company will use Sub-Contractors services which are adequate to the Company’s Data Processing Policy.</li>
                                            </ol>
                                            <h1>Links to third parties web sites<span lang="HE" dir="RTL"></span></h1>
                                            <ol start="13">
                                              <li><span dir="LTR"></span>The Company may present to Users while using the Service, links to websites which are not controlled by the Company and therefore the Company is not responsible to any of the content which is presented on third parties’ websites. Third parties websites may collect Personal Information from Users and Company will have no liability of the Personal Information protection policies and practices which are taken by third parties websites.</li>
                                            </ol>
                                            <h1>Contact</h1>
                                            <ol start="14">
                                              <li><span> </span><span dir="LTR"></span>If User has any reason to believe that the security of User’s Personal Information has been compromised or the Personal Information was misused, User should contact the Company promptly at privacy@outicks.com<span></span></li>
                                              <li><span dir="LTR"></span>For any additional inquiries concerning the Company’s Privacy Policy, or to Opt Out from part, or all of the Service, or to execute User’s legal rights, you may approach our team at <span><a href="mailto:team@outicks.com">team@outicks.com</a></span><span> </span>which will respond and cooperate to provide Users with the assurance that User Personal Information is protected and used only for the purpose of the Service.</li>
                                              <li><span dir="LTR"></span>If a major change is expected to this Privacy Policy which may affect the current legal rights of the service Users, the Company will inform Users via email messages or while logging in to the Service, or in any other mean the company will consider as appropriate at the time.</li>
                                            </ol>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </section>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </article>
          </div>
        </div>
      </div>
      
    <Footer />
  </div>

  <script
    dangerouslySetInnerHTML={{
        __html: `
          jQuery(document).ready(function(){jQuery('#menu-item-92 a').addClass('elementor-button-link elementor-button').css('background-color','#C60051').css('color','white');});
          `
    }}
  />

  <button className="hestia-scroll-to-top">
      <i className="fa fa-angle-double-up" aria-hidden="true"></i>
  </button>

  <script
    dangerouslySetInnerHTML={{
        __html: `
        var requestpost={"ajaxurl":"https:\/\/outicks.com\/wp-admin\/admin-ajax.php","disable_autoslide":"","masonry":""};
        `
    }}
  />

  <script
    dangerouslySetInnerHTML={{
        __html: `
        var elementorFrontendConfig={"environmentMode":{"edit":false,"wpPreview":false},"is_rtl":"","breakpoints":{"xs":0,"sm":480,"md":768,"lg":1025,"xl":1440,"xxl":1600},"version":"2.4.6","urls":{"assets":"https:\/\/outicks.com\/wp-content\/plugins\/elementor\/assets\/"},"settings":{"page":[],"general":{"elementor_global_image_lightbox":"yes","elementor_enable_lightbox_in_editor":"yes"}},"post":{"id":44,"title":"Time Off Management","excerpt":""}};
        `
    }}
  />

  <script
    dangerouslySetInnerHTML={{
        __html: `
        COOKIES_ENABLER.init({scriptClass:'ginger-script',iframeClass:'ginger-iframe',acceptClass:'ginger-accept',disableClass:'ginger-disable',dismissClass:'ginger-dismiss',bannerClass:'ginger_banner-wrapper',bannerHTML:document.getElementById('ginger-banner-html')!==null?document.getElementById('ginger-banner-html').innerHTML:'<div className="ginger_banner bottom dialog dark ginger_container ginger_container--open">'+'<p className="ginger_message">'+'<!--googleoff: index-->By using this site, you agree to our updated <a href=\"/privacy-policy\">Privacy Policy<\/a> and our <a href=\"/terms-of-service\">Terms of Use<\/a>.<!--googleon: index-->'+'</p>'+'<a href="#" className="ginger_btn ginger-accept ginger_btn_accept_all">'+'Accept Cookie'+'<\/a>'+'<\/div>',cookieDuration:365000,eventScroll:false,scrollOffset:20,clickOutside:true,cookieName:'ginger-cookie',forceReload:false,iframesPlaceholder:true,iframesPlaceholderClass:'ginger-iframe-placeholder',iframesPlaceholderHTML:document.getElementById('ginger-iframePlaceholder-html')!==null?document.getElementById('ginger-iframePlaceholder-html').innerHTML:'<p><!--googleoff: index-->This website uses cookies.<!--googleon: index-->'+'<a href="#" className="ginger_btn ginger-accept">Accept Cookie</a>'+'<\/p>'});
        `
    }}
  />
</>   
)}

}
